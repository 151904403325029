import React from "react"
import {Link} from "gatsby"

//input object with categories (name, slug, wpid), output unorderlist met tags

const CategoryList = (props) =>


{
    let cats = props.categories


    return (
        <ul className="StyledMetaTax">
            {cats.map(category => (

                <li key={category.name}><Link className="StyledLink" to={`/category/${category.slug}/`}>{category.name}</Link></li>
            ))}
        </ul>
    )
}


export default CategoryList;