import React, { Component } from "react"
import { Link, graphql } from "gatsby"
import PropTypes from "prop-types"

import Layout from "../components/layout"
import Img from "gatsby-image";
import Cats from "../components/categoryList"
import Tags from "../components/tagList"
import SEO from "../components/seo";



class Blog extends Component {
    render() {
        const data = this.props.data

        return (
            <Layout>
                <SEO
                    title="Blog"
                    description="Blogs about my discoveries developing with React, Javascript, Gatsby and Wordpress"
                />


                    {data.allWordpressPost.edges.map(({ node }) => (
                        <div key={node.id}>



                            <h2 className="click-header" >
                            <Link to={`/blog/${node.slug}`}>
                               {node.title}
                            </Link>
                            </h2>



                            <Img
                                alt={node.featured_media.alt_text}
                                fluid={node.featured_media.localFile.childImageSharp.fluid}

                            />

                            <div className="metadata">
                                {node.formatted_date}:
                                {node.categories && (
                                    <Cats categories={node.categories}  />
                                    )}
                                {node.tags && (
                                     <Tags tags={node.tags}  />
                                    )}
                            </div>
                            <div

                                dangerouslySetInnerHTML={{ __html: node.excerpt }}
                            />
                            <Link
                                to={`/blog/${node.slug}`}
                                aria-label={`Continue reading ${node.title}`}
                            >
                                <button  className="StyledLinkButton">
                                    Continue reading
                                </button>
                            </Link>
                        </div>
                    ))}

            </Layout>
        )
    }
}

Blog.propTypes = {
    data: PropTypes.object.isRequired,
    edges: PropTypes.array,
}

export default Blog

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date], order: DESC }) {
      edges {
        node {
        id
          title
          excerpt
          slug
          date   
          formatted_date
            categories {
      name
      slug
      wordpress_id
    }
    tags {
          name
          slug
          wordpress_id
        }   
   
  
           featured_media {
        source_url
        alt_text
        localFile {
          childImageSharp {
            fluid(maxWidth: 804) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
        }
      }
    }
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
